.slideshow {
    position: relative;
    height: 400px;
    margin: auto;
    overflow: hidden;
    border-radius: 15px; /* This should match the image border-radius */
  }

  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
  .image {
    width: 100%;
    display: block;
  }
  
  .left-arrow, .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 30px;
    color: white;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    display: none;
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  .slideshow:hover .left-arrow,
  .slideshow:hover .right-arrow {
    display: block;
  }
  

  .dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .dot.active {
    background-color: #717171;
  }
  
  /* Ensure arrows are visible above the dots */
  .left-arrow, .right-arrow {
    z-index: 1000;
  }