.service-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 20px; /* Adds space between the description and slideshow */
}

.service-description {
  flex: 1;
  padding-right: 20px; /* Adds padding to the right of the description */
}

p{
  text-align: justify; /* Adjusts the text alignment; you can change it to 'left' if you prefer */
}

.service-slideshow {
  flex: 1;
  padding-left: 20px; /* Adds padding to the left of the slideshow */
  max-width: 400px; /* Sets a maximum width for the slideshow */
}

/* Zig-zag pattern for alternate rows */
.service-row.reverse .service-description {
  padding-left: 20px; /* For reverse rows, add padding to the left */
  padding-right: 0; /* Reset the right padding for reverse rows */
}

.service-row.reverse .service-slideshow {
  padding-right: 20px; /* For reverse rows, add padding to the right */
  padding-left: 0; /* Reset the left padding for reverse rows */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .service-row,
  .service-row.reverse {
    flex-direction: column;
  }
  
  .service-description,
  .service-slideshow {
    max-width: 100%;
    padding: 0; /* Reset padding on smaller screens */
    text-align: left; /* Text alignment for smaller screens */
  }
}
