.header {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header.sticky {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.phone-info {
  flex: 1;
}

.logo-container {
  flex: 1;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.logo {
  height: 200px; /* Adjust to your preference */
}

.logo.small {
  height: 60px;
}

.language-contact-container {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.language-switch {
  cursor: pointer;
}

.contact-button {
  padding: 10px 20px;
  margin-right: 30px;
  border: none;
  background-color: #004a99;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #003366;
}

.nav {
  display: flex;
  justify-content: center;
  padding-top: 10px; /* Add padding to separate from the top row */
}

.nav a {
  padding: 10px 30px; /* Increase horizontal padding for wider buttons */
  margin: 0 25px; /* Adjust the margin as needed */
  border: none;
  color: #333; /* Dark text color for contrast */
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: 500; /* Optional: Makes the font a little bolder */
}

.nav a:hover {
  background-color: #bdbdbd7c; /* A slightly darker shade for hover state */
}

.phone-number {
  display: block; /* Ensure the number is under the text */
  font-size: 1.2em; /* Make the phone number larger */
  font-weight: bold; /* Make the phone number text thicker */
  color: #003366; /* Set the color to #003366 */
}

.flag-icon {
  height: 17px; /* Set the size of the flag icon */
  width: 17px;
}
