.contact-details {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    gap: 10px; /* Spacing between each line */
  }
  
  .contact-details > div {
    display: flex;
    align-items: center; /* Align icon with text */
  }
  
  .contact-details img {
    margin-right: 10px; /* Spacing between icon and text */
  }
  
  .contact-details p {
    margin: 0; /* Removes default margin */
    font-size: 1em; /* Adjust the font size as needed */
  }