body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .App {
    text-align: center;
  }
  
  #about, #services, #contact {
    padding: 20px;
    margin: 20px auto;
    max-width: 750px;
  }
  
  h2,h3 {
    color: #004a99; /* Based on the logo color */
  }
  
  button {
    background-color: #004a99; /* Based on the logo color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  input, textarea {
    margin: 10px 0;
    padding: 10px;
    width: calc(100% - 22px);
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    height: 100px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }

  .team-container {
    display: flex;
    justify-content: center;
    gap: 50px; /* Adjust the space between team member profiles */
    padding: 20px 0;
  }
  
  .team-member {
    text-align: center; /* Center the content for each team member */
  }
  
  .team-photo {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    border-radius: 50%; /* This will make the image round */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    margin-bottom: 10px; /* Space between the photo and the name */
    border: 3px solid #d3d3d3; 
  }
  